/* eslint no-console:0 */
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'flatpickr/dist/flatpickr.css'

import '@rails/ujs'
import * as ActiveStorage from "@rails/activestorage"

import '../lib/direct_uploads'
import '~/controllers'

import '../stylesheets/cce/index.sass'
import '../assets/images/cce'
import '../assets/images/shared'

import '../components/home/home'
import '../components/products_index/products_index'
import '../components/grow_and_sell/grow_and_sell'
import '../components/product_card/product_card'
import '../components/charges_show/charges_show'
import '../components/login_container/login_container'
import '../components/dashboard_page/dashboard_page'
import '../components/listing_new/listing_new'
import '../components/listings_index/listings_index'
import '../components/listing_edit/listing_edit'
import '../components/listing_form/listing_form'
import '../components/blog_posts_index/blog_posts_index'

// checkout JS
import '../components/shopping-cart/shopping-cart'
import '../components/checkout/shipping'

ActiveStorage.start()
