import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    id: Number
  }

  connect() {
    this.startPolling()
  }

  disconnect() {
    this.stopPolling()
  }

  startPolling() {
    this.pollingInterval = setInterval(() => {
      this.checkProgress()
    }, 2000)
  }

  stopPolling() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval)
    }
  }

  async checkProgress() {
    try {
      const response = await fetch(`/shipping_optimizations/${this.idValue}/progress`, {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Cache-Control": "no-cache"
        }
      })
      const data = await response.json()

      switch (data.status) {
        case "pending":
          this.updatePendingState(data)
          break
        case "done":
          this.stopPolling()
          this.updateDoneState(data)
          break
        case "failed":
          this.stopPolling()
          window.location.reload()
          break
        default:
          // For “too_much_for_ltl” or any unexpected status
          this.stopPolling()
          window.location.reload()
          break
      }
    } catch (err) {
      this.stopPolling()
      window.location.reload()
    }
  }

  updatePendingState(data) {
    const titleEl = document.getElementById("order-optimizer-current-product-lookup-title")
    const progressBar = document.querySelector(".order-optimizer-progress-bar")
    const progressText = document.getElementById("order-optimizer-current-product-lookup-progress-text")

    if (titleEl) titleEl.innerHTML = data.current_suggestion_lookup_title
    if (progressBar) progressBar.style.width = `${data.current_suggestion_lookup_progress_percent * 2}px`
    if (progressText) progressText.innerHTML = data.current_suggestion_lookup_progress_percent
  }

  updateDoneState(data) {
    const progressBar = document.querySelector(".order-optimizer-progress-bar")
    const progressText = document.getElementById("order-optimizer-current-product-lookup-progress-text")

    if (progressBar) progressBar.style.width = `${data.current_suggestion_lookup_progress_percent * 2}px`
    if (progressText) progressText.innerHTML = data.current_suggestion_lookup_progress_percent

    // Now fetch the "done" partial and replace the order-optimizer-content
    fetch(`/shipping_optimizations/${this.idValue}/done_html`, {
      method: "GET",
      headers: {
        "Accept": "text/html"
      }
    })
    .then(response => response.text())
    .then(html => {
      const contentContainer = document.querySelector(".order-optimizer-content")
      if (contentContainer) {
        contentContainer.innerHTML = html
      }
    })
  }
}