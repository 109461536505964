document.addEventListener('DOMContentLoaded', () => {
  // Auto-update quantity on input
  let quantityInputPrevFocusValue = null
  let quantityInputFocusedInterval = null

  const updateFunction = async (e) => {
    e.preventDefault()

    const orderItemId = e.target.dataset.id
    const updateForm = document.getElementById(`edit_api_models_order_item_${orderItemId}`)

    try {
      const response = await fetch(updateForm.action, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: new URLSearchParams(new FormData(updateForm))
      })
      const data = await response.json()

      document.getElementById("total-amount").innerHTML = data.orderSubtotal
      document.getElementById(`cart-total-price-${orderItemId}`).innerHTML = data.orderItemTotal
      document.getElementById(`cart-row-price-${data.orderItemId}`).innerHTML = `$${data.orderItemPrice.toFixed(2)}`
      document.getElementById(`error-${orderItemId}`).classList.remove('-visible')
      document.querySelector(".checkout-button").disabled = false

      if (data.discountAmount) {
        // Update discount amount
        document.getElementById("promo-code-pre-discount-amount").innerHTML = data.orderSubtotal
        // Update subtotal to reflect
        document.getElementById("promo-code-discount-amount").innerHTML = `$${data.discountAmount.toFixed(2)}`
        document.getElementById("total-amount").innerHTML = `$${(Number(data.orderSubtotal.replace(/[^0-9\.-]+/g,"")) - data.discountAmount).toFixed(2)}`
      }
    } catch (error) {
      if (!error.responseJSON) {
        Sentry?.captureMessage('Error updating item in cart: ' + error)
      }
      const quantityError = error.responseJSON?.errors?.quantity?.[0]?.replace(',is not a number', '')
      if (quantityError) {
        const errorElement = document.getElementById(`error-${orderItemId}`)
        errorElement.innerHTML = 'Quantity ' + quantityError
        errorElement.classList.add('-visible')
        document.querySelector(".checkout-button").disabled = true
      }
    }
  }

  // Auto-update quantity on change
  document.querySelectorAll(".cart-quantity").forEach(input => {
    input.addEventListener('change', (e) => {
      if (e.target.value <= 1) {
        e.target.value = 1
      }
      updateFunction(e)
    })
  })

  // Prevent clicking "enter"/submit on cart quantity input fields
  document.querySelectorAll(".edit_order_item").forEach(form => {
    form.addEventListener('submit', (e) => e.preventDefault())
  })

  // Periodically update quantity if still having focus
  document.querySelectorAll(".cart-quantity").forEach(input => {
    input.addEventListener('focus', (e) => {
      quantityInputPrevFocusValue = e.target.value
      quantityInputFocusedInterval = setInterval(() => checkUpdateOrderItemQuantity(e), 500)
    })
  })

  function checkUpdateOrderItemQuantity(e) {
    const orderItemId = e.target.dataset.id
    const activeElement = document.activeElement

    // If input still has focus
    if (activeElement?.dataset?.id === orderItemId) {
      // And value is different
      if (e.target.value !== quantityInputPrevFocusValue) {
        quantityInputPrevFocusValue = e.target.value
        if (e.target.value !== "") {
          updateFunction(e)
        }
      }
    } else {
      clearInterval(quantityInputFocusedInterval)
    }
  }

  // Remove order item
  const deleteFunction = async (e) => {
    e.preventDefault()

    const orderItemId = e.target.dataset.id
    const title = e.target.dataset.title

    if (confirm(`Are you sure you want to delete '${title}' from your cart?`)) {
      const cartTable = document.getElementById("cart-table")
      const cartSpinner = document.getElementById("cart-spinner")

      cartTable.style.display = 'none'
      cartSpinner.style.display = 'block'

      try {
        const response = await fetch(`/order_items/${orderItemId}`, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json'
          }
        })
        const data = await response.json()

        cartTable.style.display = 'block'
        cartSpinner.style.display = 'none'

        const itemCount = data.itemCount
        document.querySelector(".cart-count").innerHTML = itemCount
        document.getElementById("total-amount").innerHTML = data.orderSubtotal
        document.getElementById(`cart-row-${orderItemId}`).remove()

        if (itemCount === 0) {
          document.querySelector(".checkout-button").style.display = 'none'
        }

        if (data.allSameOrigin === true) {
          document.querySelector(".checkout-button").dataset.allSameOrigin = "true"
        }

        if (data.discountAmount) {
          document.getElementById("promo-code-pre-discount-amount").innerHTML = data.orderSubtotal
          document.getElementById("promo-code-discount-amount").innerHTML = `$${data.discountAmount.toFixed(2)}`
          document.getElementById("total-amount").innerHTML = `$${(parseFloat(data.orderSubtotal.slice(1)) - data.discountAmount).toFixed(2)}`
        }
      } catch (error) {
        alert("There was an error removing this item from the cart.")
        cartSpinner.innerHTML = "Please refresh the page."
        Sentry?.captureMessage('Error deleting item from cart: ' + error)
      }
    }
  }

  document.querySelectorAll(".cart-remove").forEach(button => {
    button.addEventListener('click', deleteFunction)
    button.addEventListener('touchstart', deleteFunction)
  })

  // Contact Me Modal handling
  const contactMeModal = document.getElementById('contact-me-modal')
  const modalCloseButton = document.getElementById('contact-me-close-button')
  const checkoutButton = document.querySelector('.checkout-button')

  if (checkoutButton) {
    const handleCheckoutClick = (e) => {
      if (checkoutButton.dataset.allSameOrigin === "false") {
        e.preventDefault()
        contactMeModal?.classList.remove('hidden')
      }
    }

    checkoutButton.addEventListener('touchstart', handleCheckoutClick)
    checkoutButton.addEventListener('click', handleCheckoutClick)
  }

  // Close modal functions
  const closeContactMeModal = () => {
    contactMeModal?.classList.add('hidden')
  }

  const showSuccessModal = () => {
    const successModal = document.getElementById('success-modal')
    successModal.classList.remove('hidden')

    const successCloseButton = document.getElementById('success-close-button')
    successCloseButton.addEventListener('click', () => {
      successCloseButton.addEventListener('touchstart', () => {
        successModal.classList.add('hidden')
        if (location.pathname.includes("/cart")) {
          location = "/"
        }
      })
    })
  }

  modalCloseButton?.addEventListener('click', closeContactMeModal)
  modalCloseButton?.addEventListener('touchstart', closeContactMeModal)

  // Order form submission
  const orderSubmitButton = document.getElementById('order-submit-button')
  if (orderSubmitButton) {
    const orderId = orderSubmitButton.dataset.id
    const orderForm = document.getElementById(`edit_api_models_order_${orderId}`)

    orderSubmitButton.addEventListener('click', async (event) => {
      event.preventDefault()

      document.querySelector('.contact-me-error-container').style.display = 'none'

      try {
        const response = await fetch(orderForm.action, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: new URLSearchParams(new FormData(orderForm))
        })
        const data = await response.json()

        orderForm.reset()

        if (!window.current_admin_user) {
          if (window.gtag) {
            gtag('event', 'RequestedShipping', {
              event_category: 'Lead',
              currency: 'USD',
              value: 10.00
            })
          }
          if (window.fbq) {
            fbq('track', 'InitiateCheckout', {
              currency: 'USD',
              value: 10.00
            })
          }
        }

        if (data.redirect_url) {
          window.location = data.redirect_url
        }
      } catch (error) {
        const errorJson = error.responseJSON
        let errorHtml = []

        if (errorJson?.errors?.full_messages) {
          errorJson.errors.full_messages.forEach(error => {
            errorHtml.push(`<li>${error}</li>`)
          })
        } else {
          errorHtml.push('<li>Oops! Something went wrong on our end. Please contact us for support</li>')
        }

        document.querySelector('.contact-me-errors').innerHTML = errorHtml.join('')
        document.querySelector('.contact-me-error-container').style.display = 'block'
      }
    })
  }

  // Promo code handling
  const promoCodePlaceholder = document.getElementById("promo-code-placeholder")
  promoCodePlaceholder?.addEventListener('click', () => {
    promoCodePlaceholder.style.display = 'none'
    document.getElementById("promo-code-form").classList.remove("hidden")
    document.querySelector(".promo-code-error").style.display = 'none'
  })
})
