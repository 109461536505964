import { Controller } from "stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {

  static targets = [
    // subscription related
    "SubscribeModal",
    "SubscribeModalContent",
    "SubscribeSuccessModal",
    "InputSubscriptionEmail",
    "ErrorListSubscriptionServer",
    "ErrorSubscriptionEmail",
    // bid related
    "BidForm",
    "BidSuccessMessage",
    "BidFormContent",
    "BidQuantity",
    "BidVarietySpecies",
    "BidCondition",
    "BidBuyerNotesContainer",
    "BidBuyerNotes",
    "InputBidRequestId",
    "InputBidRate", "InputBidMessage", "InputBidPackagingBulk", "InputBidPackagingTotes", "InputBidPackagingBags", "InputBidExpiresAt", "InputBidAvailableAt", "InputBidFile", "InputBidEmail", "InputBidName", "InputBidPhoneNumber", "InputBidZipcode", "InputBidBusinessTypeBusiness", "InputBidBusinessTypeFarm", "InputBidLoadingOptionDock", "InputBidLoadingOptionForklift", "InputBidLoadingOptionNone",
    "ErrorListBidServer",
    "ErrorBidMessage", "ErrorBidRate", "ErrorBidEmail", "ErrorBidName", "ErrorBidPhoneNumber", "ErrorBidZipcode",
  ]

  initialize() {
    const bidRequestRows = document.querySelectorAll(".bid-request-row")
    this.bidRequests = Array.from(bidRequestRows).map(row => row.dataset.bidRequest)
    this.bidFormData = new FormData()
    this.bidBusinessType = document.getElementById("bid_business_type_business")?.value
    this.bidLoadingOption = document.getElementById("bid_loading_option_dock")?.value
  }

  toggleBidForm(e) {
    e.preventDefault()
    const bidRequestId = e.target.dataset.bidRequestId

    if (bidRequestId) {
      const bidRequest = this.bidRequests.find(br => br['id'] === bidRequestId)
      this.InputBidRequestIdTarget.value = bidRequest.id
      this.BidQuantityTarget.innerHTML = bidRequest.quantity
      this.BidVarietySpeciesTarget.innerHTML = `${bidRequest.species} - ${bidRequest.variety}`
      this.BidConditionTarget.innerHTML = bidRequest.condition === 'Unclean' ? ' - Uncleaned' : ''

      if (bidRequest.notes) {
        this.BidBuyerNotesTarget.innerHTML = bidRequest.notes
        this.BidBuyerNotesContainerTarget.classList.remove("hidden")
      } else {
        this.BidBuyerNotesContainerTarget.classList.add("hidden")
      }
    }

    this.BidFormTarget.classList.toggle("hidden")
    this.BidSuccessMessageTarget.classList.add("hidden")
    this.BidFormContentTarget.classList.remove("hidden")
  }

  toggleSubscribeModal(e) {
    e.preventDefault()
    this.SubscribeModalTarget.classList.toggle("hidden")
    this.SubscribeSuccessModalTarget.classList.add("hidden")
    this.SubscribeModalContentTarget.classList.remove("hidden")
  }

  async validateAndSubmitSubscription(e) {
    e.preventDefault()
    e.stopPropagation()
    this.ErrorSubscriptionEmailTarget.classList.add("hidden")
    this.InputSubscriptionEmailTarget.classList.remove("border-red-400")

    if (!this.InputSubscriptionEmailTarget.value) {
      this.ErrorSubscriptionEmailTarget.classList.remove("hidden")
      this.InputSubscriptionEmailTarget.classList.add("border-red-400")
      return
    }

    const form = e.target.form
    const request = new FetchRequest(form.method, form.action, {
      body: new FormData(form),
      responseKind: 'json'
    })
    const response = await request.perform()

    if (response.ok) {
      form.reset()
      this.ErrorListSubscriptionServerTarget.classList.add("hidden")
      this.SubscribeSuccessModalTarget.classList.remove("hidden")
      this.SubscribeModalContentTarget.classList.add("hidden")

      if (!window.current_admin_user) {
        if (window.gtag) {
          gtag('event', 'SubscribeToRFBs', {
            event_category: 'Lead',
            currency: 'USD',
            value: 150.00
          })
        }
        if (window.fbq) {
          fbq('track', 'Subscribe', {
            currency: 'USD',
            value: 100.00
          })
        }
      }
    } else {
      const errorJson = await response.json
      const errorHtml = []

      if (errorJson?.errors) {
        errorJson.errors.forEach(error => {
          errorHtml.push(`<li>${error}</li>`)
        })
      }

      this.ErrorListSubscriptionServerTarget.classList.remove("hidden")
      // Use setTimeout to ensure DOM update after animation frame
      setTimeout(() => {
        this.ErrorListSubscriptionServerTarget.innerHTML = errorHtml.join("")
      }, 250)
    }
  }

  async validateAndSubmitBid(e) {
    e.preventDefault()
    this.ErrorListBidServerTarget.classList.add("hidden")
    this.ErrorBidMessageTarget.classList.add("hidden")
    this.InputBidMessageTarget.classList.remove("border-red-400")
    this.ErrorBidEmailTarget.classList.add("hidden")
    this.InputBidEmailTarget.classList.remove("border-red-400")
    this.ErrorBidNameTarget.classList.add("hidden")
    this.InputBidNameTarget.classList.remove("border-red-400")
    this.ErrorBidPhoneNumberTarget.classList.add("hidden")
    this.InputBidPhoneNumberTarget.classList.remove("border-red-400")
    this.ErrorBidZipcodeTarget.classList.add("hidden")
    this.InputBidZipcodeTarget.classList.remove("border-red-400")

    const errors = {
      message: !this.InputBidMessageTarget.value,
      rate: !this.InputBidRateTarget.value,
      email: !this.InputBidEmailTarget.value,
      name: !this.InputBidNameTarget.value,
      phoneNumber: !this.InputBidPhoneNumberTarget.value,
      zipcode: !this.InputBidZipcodeTarget.value
    }

    if (errors.message) {
      this.ErrorBidMessageTarget.classList.remove("hidden")
      this.InputBidMessageTarget.classList.add("border-red-400")
    }
    if (errors.rate) {
      this.ErrorBidRateTarget.classList.remove("hidden")
      this.InputBidRateTarget.classList.add("border-red-400")
    }
    if (errors.email) {
      this.ErrorBidEmailTarget.classList.remove("hidden")
      this.InputBidEmailTarget.classList.add("border-red-400")
    }
    if (errors.name) {
      this.ErrorBidNameTarget.classList.remove("hidden")
      this.InputBidNameTarget.classList.add("border-red-400")
    }
    if (errors.phoneNumber) {
      this.ErrorBidPhoneNumberTarget.classList.remove("hidden")
      this.InputBidPhoneNumberTarget.classList.add("border-red-400")
    }
    if (errors.zipcode) {
      this.ErrorBidZipcodeTarget.classList.remove("hidden")
      this.InputBidZipcodeTarget.classList.add("border-red-400")
    }

    if (Object.values(errors).some(Boolean)) return

    const form = e.target.form
    const packagingData = []

    if (this.InputBidPackagingBulkTarget.checked) packagingData.push("Bulk")
    if (this.InputBidPackagingTotesTarget.checked) packagingData.push("Totes")
    if (this.InputBidPackagingBagsTarget.checked) packagingData.push("Bags")

    this.bidFormData.set("bid[bid_request_id]", this.InputBidRequestIdTarget.value)
    this.bidFormData.set("bid[rate]", this.InputBidRateTarget.value)
    this.bidFormData.set("bid[message]", this.InputBidMessageTarget.value)
    packagingData.forEach(item => this.bidFormData.append("bid[packaging][]", item))
    this.bidFormData.set("bid[expires_at]", this.InputBidExpiresAtTarget.value)
    this.bidFormData.set("bid[available_at]", this.InputBidAvailableAtTarget.value)
    this.bidFormData.set("bid[email]", this.InputBidEmailTarget.value)
    this.bidFormData.set("bid[name]", this.InputBidNameTarget.value)
    this.bidFormData.set("bid[phone_number]", this.InputBidPhoneNumberTarget.value)
    this.bidFormData.set("bid[zipcode]", this.InputBidZipcodeTarget.value)
    this.bidFormData.set("bid[business_type]", this.bidBusinessType)
    this.bidFormData.set("bid[loading_option]", this.bidLoadingOption)

    const request = new FetchRequest("POST", form.action, {
      body: this.bidFormData,
      responseKind: 'json'
    })
    const response = await request.perform()

    if (response.ok) {
      form.reset()
      this.ErrorListBidServerTarget.classList.add("hidden")
      this.BidFormContentTarget.classList.add("hidden")
      this.BidSuccessMessageTarget.classList.remove("hidden")

      if (!window.current_admin_user) {
        if (window.gtag) {
          gtag('event', 'SubmitBid', {
            event_category: 'Lead',
            currency: 'USD',
            value: 100.00
          })
        }
        if (window.fbq) {
          fbq('track', 'SubmitApplication', {
            currency: 'USD',
            value: 100.00
          })
        }
      }
    } else {
      const errorJson = await response.json
      const errorHtml = []

      if (errorJson?.errors) {
        errorJson.errors.forEach(error => {
          errorHtml.push(`<li>${error}</li>`)
        })
      }

      this.ErrorListBidServerTarget.classList.remove("hidden")
      setTimeout(() => {
        this.ErrorListBidServerTarget.innerHTML = errorHtml.join("")
      }, 250)
    }
  }

  onBidBusinessTypeChange(e) {
    this.bidBusinessType = document.querySelector(`input[name='${e.target.name}']:checked`)?.value
  }

  onBidLoadingOptionChange(e) {
    this.bidLoadingOption = document.querySelector(`input[name='${e.target.name}']:checked`)?.value
  }

  onFileChange() {
    const files = this.InputBidFileTarget.files
    if (files?.[0]) {
      this.bidFormData.set('bid[seed_test_image]', files[0])
    }
  }

}
