document.addEventListener('DOMContentLoaded', () => {
  // GA & FB event tracking
  const shippingResultsContainer = document.getElementById("shipping-results-container")
  if (shippingResultsContainer) {
    if (!window.current_admin_user) {
      if (window.gtag) {
        gtag('event', 'RequestedShipping', {
          event_category: 'Lead',
          currency: 'USD',
          value: 15.00
        })
      }
      if (window.fbq) {
        fbq('track', 'InitiateCheckout', {
          currency: 'USD',
          value: 15.00
        })
      }
    }

    // Initialize shipping request polling if needed
    const shippingRequestAttemptCount = 0
    if (shippingResultsContainer.dataset.status === 'pending') {
      setTimeout(() => pollForStatusUpdate(shippingRequestAttemptCount), 5000)
    }
  }

  // Email request for shipping tracking
  const emailSubmissionHeading = document.getElementById("email-submission-heading")
  if (emailSubmissionHeading && !window.current_admin_user) {
    if (window.gtag) {
      gtag('event', 'RequestedShipping', {
        event_category: 'Lead',
        currency: 'USD',
        value: 10.00
      })
    }
    if (window.fbq) {
      fbq('track', 'InitiateCheckout', {
        currency: 'USD',
        value: 10.00
      })
    }
  }

  // Form submit button
  const calcShippingSubmit = document.getElementById("calc-shipping-submit")
  calcShippingSubmit?.addEventListener('click', (e) => {
    e.preventDefault()
    e.target.disabled = true
  })

  const updateOrderSummaryForShipping = (rate) => {
    const subtotalElem = document.getElementById("checkout-order-summary-subtotal")
    let subTotal = subtotalElem.textContent.replace("$", "").replace(",", "")
    subTotal = parseFloat(subTotal)

    const discountElem = document.getElementById("checkout-order-summary-discount")
    let discountAmount = discountElem?.textContent.replace("$", "").replace(",", "") || 0.0
    discountAmount = parseFloat(discountAmount)

    const palletFeeElem = document.getElementById("checkout-order-summary-pallet-fee")
    let palletFeeAmount = palletFeeElem?.textContent.replace("$", "").replace(",", "") || 0.0
    palletFeeAmount = parseFloat(palletFeeAmount)

    const total = Math.round((subTotal + rate + discountAmount + palletFeeAmount) * 100) / 100

    document.getElementById("checkout-order-summary-shipping-rate").innerHTML = `$${rate.toFixed(2)}`
    document.querySelectorAll(".checkout-order-summary-total-price").forEach(elem => {
      elem.innerHTML = `$${total.toFixed(2).toLocaleString()}`
    })
  }

  const updateRatePerUnitForShipping = (rate) => {
    const subtotalElem = document.getElementById("checkout-order-summary-subtotal")
    let subTotal = subtotalElem.textContent.replace("$", "").replace(",", "")
    subTotal = parseFloat(subTotal)

    const discountElem = document.getElementById("checkout-order-summary-discount")
    let discountAmount = discountElem?.textContent.replace("$", "").replace(",", "") || 0.0
    discountAmount = parseFloat(discountAmount)

    const palletFeeElem = document.getElementById("checkout-order-summary-pallet-fee")
    let palletFeeAmount = palletFeeElem?.textContent.replace("$", "").replace(",", "") || 0.0
    palletFeeAmount = parseFloat(palletFeeAmount)

    const ratePerUnitElement = document.getElementById("rate-per-unit")
    const weight = ratePerUnitElement.dataset.numUnits
    const ratePerUnit = (rate + subTotal + discountAmount + palletFeeAmount) / weight
    ratePerUnitElement.innerHTML = `$${ratePerUnit.toFixed(2).toLocaleString()}`
  }

  const handleShippingLookupError = (error) => {
    console.error('error: ', error)
    document.getElementById("shipping-result-loading").style.display = 'none'
    document.getElementById("shipping-lookup-failed").classList.add('-show')
    if (window.Sentry) {
      Sentry.captureMessage('Error on shipping lookup: ' + JSON.stringify(error))
    }
  }

  const pollForStatusUpdate = async (count) => {
    const attemptCount = count + 1

    try {
      const response = await fetch(`${window.location.href}?from_js=true`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      })
      const data = await response.json()

      if (data.status === 'pending') {
        if (attemptCount > 24) {
          handleShippingLookupError('Did not receive a result from the server before 60 seconds')
        } else {
          setTimeout(() => pollForStatusUpdate(attemptCount), 5000)
        }
      } else if (data.status === 'done') {
        if (!window.current_admin_user) {
          if (window.gtag) {
            gtag('event', 'RequestedShipping', {
              event_category: 'Lead',
              currency: 'USD',
              value: 10.00
            })
          }
          if (window.fbq) {
            fbq('track', 'InitiateCheckout', {
              currency: 'USD',
              value: 10.00
            })
          }
        }

        window.location.reload()
      } else if (data.status === 'failed') {
        document.getElementById("shipping-result-loading").style.display = 'none'
        document.getElementById("shipping-lookup-failed").classList.add('-show')
      }
    } catch (error) {
      handleShippingLookupError(error)
    }
  }

  // Handle shipping selection changes
  document.querySelectorAll("input[type=radio][name='api_models_shipping_request[shipping_selection_index]']")
    .forEach(radio => {
      radio.addEventListener('change', (e) => {
        const radioRate = document.getElementById(`shipping-result-rate-${e.target.value}`).textContent
          .replace("$", "").replace(",", "")
        const rate = parseFloat(radioRate)
        updateOrderSummaryForShipping(rate)
        updateRatePerUnitForShipping(rate)
      })
    })

  // Disable/enable continue to billing button
  const shippingToBillingButton = document.getElementById("shipping-to-billing-button")
  shippingToBillingButton?.addEventListener('click', (e) => {
    if (shippingToBillingButton.classList.contains('-disabled')) {
      e.preventDefault()
    }
  })
})
