import { Controller } from "stimulus"
import flatpickr from "flatpickr"
import Tagify from "@yaireo/tagify/dist/tagify.esm.js"

export default class extends Controller {
  static targets = [
    "ErrorsList",
    "SpeciesInput",
    "SpeciesError",
    "VarietyInput",
    "VarietyError",
    "DeliveryDateContainer",
    "QuantityInput",
    "QuantityError",
    "EmailInput",
    "EmailError",
    "NameInput",
    "NameError",
    "PhoneInput",
    "PhoneError",
    "ZipcodeInput",
    "ZipcodeError",
    "CreateSection",
    "BidFormContainer",
    "SuccessContainer",
  ]

  initialize() {
    this.inited = this.inited || null

    if (this.inited === null) {
      this.initFlatPickr()
      this.initSpeciesTagify()
      this.inited = true
    }
  }

  initFlatPickr() {
    flatpickr(".flatpickr", { wrap: true, dateFormat: 'M j, Y' })
  }

  initSpeciesTagify() {
    const speciesElem = document.getElementById("bid_request_species_tagify")
    if (!speciesElem) return

    const tagify = new Tagify(speciesElem, {
      autoComplete: { enabled: true },
      dropdown: { enabled: 0 },
      maxTags: 1,
      whitelist: eval(speciesElem.value)
    })

    tagify.on('change', (e) => {
      const valueObject = e.detail.tagify.getCleanValue()
      this.SpeciesInputTarget.value = valueObject[0]?.value || ""
    })
  }

  onCreateClick(e) {
    e.preventDefault()
    this.BidFormContainerTarget.classList.remove("hidden")
    this.CreateSectionTarget.classList.add("hidden")
  }

  onCancel(e) {
    e.preventDefault()
    this.BidFormContainerTarget.classList.add("hidden")
    this.CreateSectionTarget.classList.remove("hidden")
  }

  onDeliveryFlexibleChange(e) {
    if (e.target.checked) {
      this.DeliveryDateContainerTarget.classList.add("hidden")
    }
  }

  onDeliverySpecificChange(e) {
    if (e.target.checked) {
      this.DeliveryDateContainerTarget.classList.remove("hidden")
    }
  }

  validateFormFields() {
    this.SpeciesErrorTarget.classList.add("hidden")
    this.VarietyErrorTarget.classList.add("hidden")
    this.VarietyInputTarget.classList.remove("border-red-400")
    this.QuantityErrorTarget.classList.add("hidden")
    this.QuantityInputTarget.classList.remove("border-red-400")
    this.EmailErrorTarget.classList.add("hidden")
    this.EmailErrorTarget.innerHTML = "can't be blank"
    this.EmailInputTarget.classList.remove("border-red-400")
    this.NameErrorTarget.classList.add("hidden")
    this.NameInputTarget.classList.remove("border-red-400")
    this.PhoneErrorTarget.classList.add("hidden")
    this.PhoneInputTarget.classList.remove("border-red-400")
    this.ZipcodeErrorTarget.classList.add("hidden")
    this.ZipcodeInputTarget.classList.remove("border-red-400")

    // reverse order so scroll to element works right
    this.errorUnloadingOption = (this.dataUnloadingOption === null || this.dataUnloadingOption === '')
    this.errorBusinessType = (this.dataBusinessType === null || this.dataBusinessType === '')
    this.errorZipcode = (this.dataZipcode === null || this.dataZipcode === '')

    if (!this.ZipcodeInputTarget.value) {
      this.ZipcodeErrorTarget.classList.remove("hidden")
      this.ZipcodeInputTarget.classList.add("border-red-400")
      this.ZipcodeErrorTarget.scrollIntoView(false)
    }

    if (!this.PhoneInputTarget.value) {
      this.PhoneErrorTarget.classList.remove("hidden")
      this.PhoneInputTarget.classList.add("border-red-400")
      this.PhoneErrorTarget.scrollIntoView(false)
    }

    if (!this.NameInputTarget.value) {
      this.NameErrorTarget.classList.remove("hidden")
      this.NameInputTarget.classList.add("border-red-400")
      this.NameErrorTarget.scrollIntoView(false)
    }

    if (!this.EmailInputTarget.value) {
      this.EmailErrorTarget.classList.remove("hidden")
      this.EmailInputTarget.classList.add("border-red-400")
      this.EmailErrorTarget.scrollIntoView(false)
    }

    if (!this.QuantityInputTarget.value) {
      this.QuantityErrorTarget.classList.remove("hidden")
      this.QuantityInputTarget.classList.add("border-red-400")
      this.QuantityErrorTarget.scrollIntoView(false)
    }

    if (!this.VarietyInputTarget.value) {
      this.VarietyErrorTarget.classList.remove("hidden")
      this.VarietyInputTarget.classList.add("border-red-400")
      this.VarietyErrorTarget.scrollIntoView(false)
    }

    if (!this.SpeciesInputTarget.value) {
      this.SpeciesErrorTarget.classList.remove("hidden")
      this.SpeciesErrorTarget.scrollIntoView(false)
    }
  }

  async validateAndSubmit(e) {
    e.preventDefault()
    this.validateFormFields()
    const form = e.target.form

    try {
      const response = await fetch(form.action, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: new URLSearchParams(new FormData(form))
      })
      const data = await response.json()

      if (response.ok) {
        form.reset()
        this.BidFormContainerTarget.classList.add("hidden")
        this.SuccessContainerTarget.classList.remove("hidden")
        this.ErrorsListTarget.classList.add("hidden")

        if (!window.current_admin_user) {
          if (window.gtag) {
            gtag('event', 'RequestForBid', {
              event_category: 'Lead',
              currency: 'USD',
              value: 100.00
            })
          }
          if (window.fbq) {
            fbq('track', 'AddToWishlist', {
              currency: 'USD',
              value: 100.00
            })
          }
        }
      } else {
        const errorHtml = []
        if (data.errors) {
          data.errors.forEach(error => {
            errorHtml.push(`<li>${error}</li>`)
            if (error.includes("email")) {
              this.EmailInputTarget.classList.add("border-red-400")
              this.EmailErrorTarget.innerHTML = error
              this.EmailErrorTarget.classList.remove("hidden")
            }
          })
        }

        this.ErrorsListTarget.innerHTML = errorHtml.join('')
        this.ErrorsListTarget.classList.remove("hidden")
      }
    } catch (error) {
      console.error('Error submitting form:', error)
    }
  }
}
