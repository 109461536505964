import './home.sass'
import '../product_card/product_card.sass'

document.addEventListener('DOMContentLoaded', () => {
  const emailSubmitButton = document.getElementById('mc-embedded-subscribe')
  const emailSignupForm = document.getElementById('mc-embedded-subscribe-form')
  const emailSignupSuccess = document.getElementById('email-signup-success')
  const emailSignupError = document.getElementById('email-signup-error')

  function showEmailSignupError(error) {
    emailSignupError.innerHTML = error
    emailSignupError.classList.add('-is-visible')
  }

  if (emailSubmitButton) {
    emailSubmitButton.addEventListener('click', async (event) => {
      event.preventDefault()
      emailSignupError.classList.remove('-is-visible')

      try {
        const response = await fetch(emailSignupForm.action, {
          method: emailSignupForm.method,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: new URLSearchParams(new FormData(emailSignupForm))
        })

        if (!response.ok) {
          const errorData = await response.json()
          if (errorData) {
            showEmailSignupError(errorData.error)
          } else {
            showEmailSignupError("Error submitting form. Please contact us for assistance.")
          }
          return
        }

        emailSignupForm.classList.add('-is-hidden')
        emailSignupError.classList.add('-is-hidden')
        emailSignupSuccess.classList.add('-is-visible')

        if (!window.current_admin_user) {
          if (window.gtag) {
            gtag('event', 'EmailNewsletterSignup', {
              event_category: 'Lead',
              currency: 'USD',
              value: 20.00
            })
          }
          if (window.fbq) {
            fbq('track', 'ViewContent', {
              currency: 'USD',
              value: 20.00
            })
          }
        }

      } catch (err) {
        console.error('Error:', err)
        showEmailSignupError("Error submitting form. Please contact us for assistance.")
      }
    })
  }
})
