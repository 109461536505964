import "./listing_form.sass"
import flatpickr from "flatpickr"
import Tagify from "@yaireo/tagify/dist/tagify.esm.js"

const todayDateAsMMDDYYYY = () => {
  const today = new Date()
  const month = String(today.getMonth() + 1).padStart(2, '0')
  const day = String(today.getDate()).padStart(2, '0')
  const year = today.getFullYear()

  return [month, day, year].join('/')
}

document.addEventListener('DOMContentLoaded', () => {
  const input = document.getElementById("api_models_listing_cost_price")
  if (input) {
    input.addEventListener("mousewheel", function() { this.blur() })
  }

  // Initialize Flatpickr
  flatpickr("#api_models_listing_availability_date", {
    dateFormat: "m/d/Y",
    allowInput: true
  })

  // Initialize Tagify for species
  const speciesElem = document.getElementById("api_models_listing_species_tagify")
  if (speciesElem) {
    const tagify = new Tagify(speciesElem, {
      autoComplete: { enabled: true },
      dropdown: { enabled: 0 },
      maxTags: 1,
      whitelist: eval(speciesElem.value)
    })

    tagify.on('change', (e) => {
      const valueObject = e.detail.tagify.getCleanValue()
      const speciesInput = document.getElementById("api_models_listing_species")
      speciesInput.value = valueObject[0]?.value || ""
    })
  }

  // Handle variety VNS checkbox
  const varietyVns = document.getElementById("variety_vns")
  const varietyInput = document.getElementById("api_models_listing_variety")

  if (varietyVns) {
    varietyVns.addEventListener('change', () => {
      if (varietyVns.checked) {
        varietyInput.disabled = true
        varietyInput.value = 'VNS'
      } else {
        varietyInput.disabled = false
        varietyInput.value = ''
      }
    })
  }

  // Handle test image button
  const testImageButton = document.getElementById("listing-test-image")
  if (testImageButton) {
    testImageButton.addEventListener('click', (e) => {
      if (testImageButton.classList.contains("-disabled")) {
        e.preventDefault()
      }
    })
  }

  // Handle treated radio buttons
  const treatedFalse = document.getElementById("api_models_listing_treated_false")
  const treatedHow = document.getElementById("listing-form-treated-how")
  const treatedYesInfo = document.getElementById("listing-form-treated-yes-info")

  if (treatedFalse?.checked) {
    treatedHow.style.display = 'none'
    treatedYesInfo.style.display = 'none'
  }

  treatedFalse?.addEventListener('change', () => {
    if (treatedFalse.checked) {
      treatedHow.style.display = 'none'
      treatedYesInfo.style.display = 'none'
    }
  })

  document.getElementById("api_models_listing_treated_true")?.addEventListener('change', (e) => {
    if (e.target.checked) {
      treatedHow.style.display = 'block'
      treatedYesInfo.style.display = 'block'
    }
  })

  // Handle tested radio buttons
  const testedTrue = document.getElementById("api_models_listing_tested_true")
  const testedFalse = document.getElementById("api_models_listing_tested_false")
  const testImage = document.getElementById("listing-test-image")
  const seedTestInfo = document.getElementById("listing-form-seed-test-info")

  testedTrue?.addEventListener('change', () => {
    if (testedTrue.checked) {
      testImage.classList.remove('-disabled')
      seedTestInfo.style.display = 'none'
    }
  })

  testedFalse?.addEventListener('change', () => {
    if (testedFalse.checked) {
      testImage.classList.add('-disabled')
      seedTestInfo.style.display = 'block'
    }
  })

  // Handle PVP radio buttons
  const pvpTrue = document.getElementById("api_models_listing_pvp_true")
  const pvpFalse = document.getElementById("api_models_listing_pvp_false")
  const pvpDontKnow = document.getElementById("api_models_listing_pvp_dontknow")
  const pvpAgreement = document.getElementById("listing-form-pvp-agreement")
  const pvpTrueInfo = document.getElementById("listing-form-pvp-true-info")
  const pvpDontKnowInfo = document.getElementById("listing-form-pvp-dontknow-info")

  pvpTrue?.addEventListener('change', () => {
    if (pvpTrue.checked) {
      pvpAgreement.style.display = 'none'
      pvpTrueInfo.style.display = 'block'
      pvpDontKnowInfo.style.display = 'none'
    }
  })

  pvpFalse?.addEventListener('change', () => {
    if (pvpFalse.checked) {
      pvpAgreement.style.display = 'block'
      pvpTrueInfo.style.display = 'none'
      pvpDontKnowInfo.style.display = 'none'
    }
  })

  pvpDontKnow?.addEventListener('change', () => {
    if (pvpDontKnow.checked) {
      pvpAgreement.style.display = 'none'
      pvpTrueInfo.style.display = 'none'
      pvpDontKnowInfo.style.display = 'block'
    }
  })

  // Handle file input changes
  const fileInput = document.getElementById('api_models_listing_original_seed_test_image_input')
  fileInput?.addEventListener('change', () => {
    let fileName = fileInput.value
    const fileNameParts = fileName.split("\\")
    fileName = fileNameParts[fileNameParts.length - 1]

    document.getElementById('listing-test-image-filename').innerHTML = fileName

    if (fileName) {
      document.getElementById('listing-test-image').innerHTML = "Seed Analysis Added"
      document.getElementById('api_models_listing_original_seed_test_image').value = fileInput.value
    }
  })

  // Handle availability date
  const availabilityDateNow = document.getElementById("api_models_availability_date_now")
  const availabilityDate = document.getElementById("api_models_listing_availability_date")

  availabilityDateNow?.addEventListener('change', () => {
    if (availabilityDateNow.checked) {
      availabilityDate.disabled = true
      availabilityDate.value = todayDateAsMMDDYYYY()
    } else {
      availabilityDate.disabled = false
      availabilityDate.value = ''
    }
  })

  // Handle price calculation
  const costPriceInput = document.getElementById("api_models_listing_cost_price")
  costPriceInput?.addEventListener('keyup', async (e) => {
    const isNumber = /^[0-9]$/i.test(e.key)

    if (isNumber || e.keyCode === 8 || e.keyCode === 46) {
      const costPrice = costPriceInput.value
      const container = document.getElementById("listing-retail-price-container")
      const species = document.getElementById("api_models_listing_species").value

      if (costPrice > 0) {
        try {
          const response = await fetch(`/markup_price?retail_supplier=false&species=${species}&price_per=${costPrice}`, {
            method: 'GET',
            headers: {
              'Accept': 'application/json'
            }
          })

          const data = await response.json()
          const retailPrice = data.price.toFixed(2)

          container.classList.add("-is-visible")
          document.getElementById("retail-price-value").innerHTML = retailPrice
        } catch (error) {
          console.error('Error:', error)
        }
      } else {
        container.classList.remove("-is-visible")
      }
    }
  })

  // Handle form submission
  const listingSubmit = document.getElementById("listing-submit")
  listingSubmit?.addEventListener('click', (e) => {
    document.querySelector(".listing-uploading-notification").classList.add("-visible")

    document.querySelectorAll(".listing-form input[type='text']").forEach(input => {
      if (input.disabled) {
        input.disabled = false
      }
    })

    listingSubmit.disabled = true
    document.querySelector(".listing-form").submit()
  })

  // Handle initial page load states
  const listingVariety = document.getElementById("listing_variety")
  if (listingVariety?.value === "VNS") {
    document.getElementById("variety_vns").checked = true
    listingVariety.disabled = true
  }

  if (document.getElementById("api_models_listing_tested_true")?.checked) {
    document.getElementById("listing-test-image").classList.remove('-disabled')
    document.getElementById("listing-form-seed-test-info").style.display = 'none'
  }

  if (document.getElementById("api_models_listing_tested_false")?.checked) {
    document.getElementById("listing-form-seed-test-info").style.display = 'block'
  }

  if (document.getElementById("api_models_listing_availability_date")?.value === todayDateAsMMDDYYYY()) {
    document.getElementById("availability_date_now").checked = true
    document.getElementById("listing_availability_date").disabled = true
  }

  if (document.getElementById("api_models_listing_pvp_true")?.checked) {
    document.getElementById("listing-form-pvp-true-info").style.display = 'block'
  }

  if (document.getElementById("api_models_listing_pvp_false")?.checked) {
    document.getElementById("listing-form-pvp-agreement").style.display = 'block'
  }

  if (document.getElementById("api_models_listing_pvp_dontknow")?.checked) {
    document.getElementById("listing-form-pvp-dontknow-info").style.display = 'block'
  }

  // Handle initial price calculation display
  const initialCostPrice = document.getElementById("api_models_listing_cost_price")?.value
  if (initialCostPrice > 0) {
    const retailPrice = (initialCostPrice * 1.1765).toFixed(2)
    document.getElementById("retail-price-value").innerHTML = retailPrice
    document.getElementById("listing-retail-price-container").classList.add("-is-visible")
  }

  // Handle existing file
  const testImageFilename = document.getElementById("listing-test-image-filename")
  if (testImageFilename?.textContent.trim().length > 0) {
    document.getElementById("listing-test-image").classList.remove('-disabled')
    document.getElementById("listing-form-seed-test-info").style.display = 'none'
  }
})
